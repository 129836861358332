import(/* webpackMode: "eager" */ "/var/www/vhosts/barturs.com/httpdocs/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/barturs.com/httpdocs/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"700\"],\"style\":[\"normal\"],\"display\":\"swap\",\"adjustFontFallback\":false,\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/barturs.com/httpdocs/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Merriweather\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"700\",\"900\"],\"style\":[\"normal\"],\"display\":\"swap\",\"adjustFontFallback\":false,\"variable\":\"--font-merriweather\"}],\"variableName\":\"merriweather\"}");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/barturs.com/httpdocs/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"],\"display\":\"swap\",\"adjustFontFallback\":false,\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/www/vhosts/barturs.com/httpdocs/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/barturs.com/httpdocs/src/components/LoadingAnimation/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/barturs.com/httpdocs/src/components/PageLoader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/barturs.com/httpdocs/src/hooks/context/localeContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/var/www/vhosts/barturs.com/httpdocs/src/hooks/providers/AuthProvider.tsx");
